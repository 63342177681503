.siw-main-view {
  .siw-main-body {
    a.resend-link {
      cursor: pointer;
    }

    span.strong {
      font-weight: bold;
      color: $dark-text-color;
      word-break: break-all;
    }
  }
}



