.success-redirect {
  .okta-waiting-spinner {
    // more space between title and spinner
    margin-top: 24px;
  }
}

.hide-underline {
  text-decoration: none;
}
