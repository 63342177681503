// TODO:
// move to _variables.scss
// once the file can be copied to target folder in okta-core/clients/loginpage
$od-secondary-button-bg-color: #fff;
$od-secondary-button-text-color: #1662dd;
$od-secondary-button-action-bg-color: #e5edfb;
$od-secondary-button-action-text-color: #124a94;

/* ======= Buttons ======= */

.button,
// link-button is a link that looks like a button
.button.link-button {
  display: inline-block;
  height: $input-height;
  line-height: $button-line-height;
  padding: $button-padding;
  border: 1px solid;
  border-radius: $border-radius-default;
  font-size: $font-size;
  box-sizing: border-box;
  -webkit-appearance: none;
}

// button-link is a link that looks like a button
.button-link {
  color: $link-text-color;
  background: none;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.button-wide,
.button-wide.button-primary {
  width: 100%;
  height: 50px;
  line-height: 48px;
  text-align: center;
}

.icon-button {
  display: block;
  width: 22px;
  height: 22px;
  text-align: center;
  border: 1px solid;
  border-radius: $border-radius-default;

  .icon {
    display: inline-block;
    position: relative;
    top: 4px;
    opacity: 0.25;
  }

  &:hover .icon {
    opacity: 0.5;
  }
}

.sms-request-button.link-button,
.call-request-button.link-button {
  text-align: center;
  width: 140px;
  padding-left: 6px;
  padding-right: 6px;
  // This css will break the text into two lines if it's too long.
  white-space: normal;
  overflow: visible;
  line-height: 1;
  padding-top: 12px;

  @include max-width-mq($container-width) {
    width: 100%;
    margin-bottom: 15px;
  }
}

.email-request-button {
  text-align: center;
  @include max-width-text(130px);

  @include max-width-mq($container-width) {
    width: 100%;
    margin-bottom: 15px;
  }
}

.social-auth-button.link-button {
  @extend .button-wide;
  border-radius: $border-radius-default;
  box-sizing: border-box;

  color: #fff;
  display: block;
  font-size: $font-size;
  line-height: 50px;
  margin-top: 15px;
  padding-left: 90px;
  padding-right: 40px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-of-type {
    margin-bottom: 25px;
  }

  &:active,
  &:focus,
  &:hover,
  &:visited {
    text-decoration: none;
  }
}

.button {
  .okta-verify-authenticator,
  .okta-webauthn-authenticator {
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    vertical-align: middle;
    display: inline-block;
    margin: 0 0.83em 0 0;
  }
}

.default-custom-button.link-button {
  @extend .button-wide;
  border-radius: $border-radius-default;
  box-sizing: border-box;

  color: #fff;
  display: block;
  font-size: $font-size;
  line-height: 50px;
  margin-top: 15px;
  text-align: center;

  &:last-of-type {
    margin-bottom: 25px;
  }

  &:active,
  &:focus,
  &:hover,
  &:visited {
    text-decoration: none;
  }
}

.button-secondary.link-button {
  @extend .button-wide;
  color: $od-secondary-button-text-color;
  border: 1px solid $od-secondary-button-text-color;
  background-color: $od-secondary-button-bg-color;
  border-radius: $border-radius-default;
  box-sizing: border-box;

  display: block;
  font-size: $font-size;
  margin-top: 15px;
  text-align: center;

  &:last-of-type {
    margin-bottom: 25px;
  }
  &:active,
  &:focus,
  &:hover {
    color: $od-secondary-button-action-text-color;
    border-color: $od-secondary-button-action-text-color;
    background-color: $od-secondary-button-action-bg-color;
  }

  &:active,
  &:focus,
  &:hover,
  &:visited {
    text-decoration: none;
  }
}

.piv-button.link-button {
  @extend .button-wide;
  border-radius: $border-radius-default;
  box-sizing: border-box;

  display: block;
  font-size: $font-size;
  line-height: 50px;
  margin-top: 15px;
  text-align: center;

  &:last-of-type {
    margin-bottom: 25px;
  }

  &:active,
  &:focus,
  &:hover,
  &:visited {
    text-decoration: none;
  }
}

@mixin social-auth-button-colorless-template($bg-image) {
  background-color: $other-label-bg-color;
  background: $bg-image no-repeat 12px/24px,
    linear-gradient(90deg, $other-icon-bg-color 50px, $other-label-bg-color 50px);
  border: 1px solid $other-border-color;

  &:active,
  &:focus,
  &:hover {
    background: $bg-image no-repeat 12px/24px,
      linear-gradient(90deg, lighten($other-icon-bg-color, 3%) 50px, lighten($other-label-bg-color, 3%) 50px);
  }

  &.link-button {
    color: $dark-text-color;
  }
}

@mixin default-custom-button-template($background-color, $border-color) {
  background-color: $background-color;
  border: 1px solid $border-color;

  &:active,
  &:focus,
  &:hover {
    background-color: lighten($background-color, 3%);
  }
}

@mixin piv-button-template($background-color, $border-color, $action-background-color, $action-border-color) {
  color: $border-color;
  border: 2px solid $border-color;
  background-color: $background-color;

  &:active,
  &:focus,
  &:hover {
    color: $action-border-color;
    border-color: $action-border-color;
    background-color: $action-background-color;
  }
}

.social-auth-facebook-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/facebook_logo.png'));
}

.social-auth-google-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/google_logo.png'));
}

.social-auth-apple-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/apple_logo.png'));
}

.social-auth-linkedin-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/linkedin_logo.png'));
}

.social-auth-microsoft-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/microsoft_logo.png'));
}

.social-auth-general-idp-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/default_logo.png'));
}

.default-custom-button {
  @include default-custom-button-template($default-custom-button-background-color, $default-custom-button-border-color);
}

.piv-button.link-button {
  @include piv-button-template($piv-button-background-color, $piv-button-border-text-color, $piv-action-button-background-color, $piv-action-button-border-text-color);
}

.social-auth-github-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/github_logo.png'));
}

.social-auth-gitlab-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/gitlab_logo.png'));
}

.social-auth-yahoo-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/yahoo_logo.png'));
}

.social-auth-line-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/line_logo.png'));
}

.social-auth-paypal-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/paypal_logo.png'));
}

.social-auth-paypal_sandbox-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/paypal_sandbox_logo.png'));
}

.social-auth-salesforce-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/salesforce_logo.png'));
}

.social-auth-amazon-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/amazon_logo.png'));
}

.social-auth-yahoojp-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/yahoojp_logo.png'));
}

.social-auth-discord-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/discord_logo.png'));
}

.social-auth-adobe-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/adobe_logo.png'));
}

.social-auth-orcid-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/orcid_logo.png'));
}

.social-auth-spotify-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/spotify_logo.png'));
}

.social-auth-xero-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/xero_logo.png'));
}

.social-auth-quickbooks-button {
  @include social-auth-button-colorless-template(url('../img/icons/login/quickbooks_logo.png'));
}
